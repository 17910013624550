import { useRouter } from 'next/router';
import React, {
	FunctionComponent, MouseEvent, useCallback,
} from 'react';
import styled from 'styled-components';
import { useTranslation } from 'next-i18next';

import Link from 'next/link';
import Theme from '../../styles/Theme';
import { IconCrossSVG } from './svg/IconCrossSVG';
import { DropdownLang } from '../common/DropdownLang';
import { MapLogoColor } from '../logo/MapLogoColor';

interface Props {
	onClose: () => void;
	translation: boolean;
}

const StyledContainer = styled.div`
	position: absolute;
	z-index: 100;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	background: ${(props) => props.theme.white};
`;

const StyledCloseContainer = styled.div`
	display: flex;
	justify-content: flex-end;
    align-items: flex-end;
	padding: ${(props) => 16 / props.theme.fontSizeBase}rem ${(props) => 20 / props.theme.fontSizeBase}rem;

	cursor: pointer;
`;

const StyledLogoContainer = styled.div`
	padding: 0 ${(props) => 32 / props.theme.fontSizeBase}rem;
`;

const StyledItems = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${(props) => 8 / props.theme.fontSizeBase}rem;
	margin: ${(props) => 32 / props.theme.fontSizeBase}rem 0 0 0;
	padding: 0 ${(props) => 32 / props.theme.fontSizeBase}rem;
`;

const StyledItem = styled(Link)`
	font-family: 'Plus Jakarta Sans', sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: ${(props) => 16 / props.theme.fontSizeBase}rem;

	color: ${(props) => props.theme.black};
	height: ${(props) => 40 / props.theme.fontSizeBase}rem;

	display: flex;
	align-items: center;
	text-decoration: none;
`;

const StyledItemsBottom = styled.div`
	display: flex;
	flex-direction: column;
	margin: auto 0 ${(props) => 32 / props.theme.fontSizeBase}rem 0;
	padding: 0 ${(props) => 32 / props.theme.fontSizeBase}rem;
`;

export const MapMenu: FunctionComponent<Props> = ({ onClose, translation = true }: Props) => {
	const { t } = useTranslation(['common']);
	const router = useRouter();

	const goHome = useCallback(() => {
		router.push('/');
	}, [router]);

	const goTo = useCallback(async (event: any) => {
		if (event) {
			(event as unknown as MouseEvent).preventDefault();
			await router.push(
				((event as unknown as MouseEvent).target as unknown as HTMLAnchorElement).href,
			);
		}
		onClose();
	}, [onClose, router]);

	return (
		<StyledContainer>
			<StyledCloseContainer>
				<IconCrossSVG width={24} height={24} color={Theme.black} onClick={onClose}/>
			</StyledCloseContainer>
			<StyledLogoContainer onClick={goHome}>
				<MapLogoColor width={106} height={53}/>
			</StyledLogoContainer>
			<StyledItems>
				<StyledItem href="/experience" onClick={goTo}>{ t('common:menuExperience') }</StyledItem>
				<StyledItem href="/aide" onClick={goTo}>{ t('common:menuHelp') }</StyledItem>
				<StyledItem href="/droits-et-credits" onClick={goTo}>{ t('common:menuCredits') }</StyledItem>
				<StyledItem href="/cgu" onClick={goTo}>{ t('common:menuCgu') }</StyledItem>
				<StyledItem href="/politique-de-confidentialite" onClick={goTo}>{ t('common:menuPrivacy') }</StyledItem>
			</StyledItems>
			<StyledItemsBottom>
				{ translation && <DropdownLang fontSize={16} padding={16} marginBottom={8} /> }
			</StyledItemsBottom>
		</StyledContainer>
	);
};
