import {
	memo, SVGProps,
} from 'react';

// eslint-disable-next-line react/display-name
export const IconMenuBurgerSVG = memo<SVGProps<SVGSVGElement>>((props: SVGProps<SVGSVGElement>) => (
	<svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path fillRule="evenodd" clipRule="evenodd" d="M2.884 6.4454C8.61052 5.94012 14.5055 5.77169 20.4004 6.10855C21.411 6.10855 21.411 4.42427 20.4004 4.42427C14.5055 4.08742 8.61052 4.25585 2.884 4.76113C1.70501 4.92956 1.70501 6.61383 2.884 6.4454ZM3.38878 13.0138C9.28372 12.3401 15.1787 12.3401 21.242 12.677C22.2526 12.677 22.2526 10.9927 21.242 10.9927C15.1787 10.6559 9.28372 10.6559 3.38878 11.3296C2.20979 11.498 2.20979 13.1823 3.38878 13.0138ZM11.979 19.5832C8.98898 19.5408 6.02046 19.4987 3.05194 19.4139C2.04137 19.4139 2.04137 17.7296 3.05194 17.7296C5.99941 17.8138 8.98899 17.8559 11.9786 17.898C14.9681 17.9401 17.9577 17.9822 20.9052 18.0664C21.9158 18.0664 21.9158 19.7507 20.9052 19.7507C17.8952 19.6671 14.9266 19.625 11.979 19.5832Z" fill={props.color} />
	</svg>
));
