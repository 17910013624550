import {
	memo, SVGProps,
} from 'react';

// eslint-disable-next-line react/display-name
export const IconCrossSVG = memo<SVGProps<SVGSVGElement>>((props: SVGProps<SVGSVGElement>) => (
	<svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path fillRule="evenodd" clipRule="evenodd" d="M16.2965 5.68859C17.2678 4.87102 18.2311 4.06008 19.1734 3.24914C19.6032 3.03338 20.2479 2.81762 20.6777 3.24914C21.1074 3.68066 21.1074 4.32795 20.6777 4.75948C20.3413 5.04895 20.0076 5.33572 19.6761 5.6207C17.5974 7.40719 15.6008 9.12324 13.5381 10.9917C16.057 13.6496 18.4611 16.3995 20.6871 19.2093C21.1043 19.6306 21.1043 20.2626 20.6871 20.684C20.2698 21.1053 19.644 21.1053 19.2267 20.684C16.9915 17.7496 14.5766 14.9966 12.0462 12.3602C11.9866 12.4156 11.9269 12.4712 11.8671 12.5269C11.2224 13.1742 10.524 13.8215 9.82561 14.4688C9.12721 15.1161 8.42881 15.7633 7.78414 16.4106C6.70968 17.4894 5.63522 18.784 5.20543 20.2943C4.77565 21.5889 2.62673 20.9416 3.05651 19.6471C3.4863 17.921 4.56076 16.4106 5.85011 15.1161C6.49479 14.4688 7.19319 13.8215 7.89159 13.1742C8.58998 12.5269 9.28838 11.8796 9.93306 11.2323C10.1095 11.0679 10.2866 10.9042 10.4644 10.7411C8.76437 9.03076 7.01981 7.36537 5.24917 5.72632C4.41469 4.88363 5.87503 3.40893 6.70951 4.25162C8.50036 5.90937 10.2645 7.62103 11.9829 9.37695C13.4096 8.11878 14.8616 6.89645 16.2965 5.68859Z" fill={props.color} />
	</svg>
));
