import { useEffect, useState } from 'react';

export function useSreenHeight() {
	const [screenHeight, setScreenHeight] = useState<number>(0);

	useEffect(() => {
		if (window) {
			setScreenHeight(window.innerHeight);

			const updateWindowDimensions = () => {
				setScreenHeight(window.innerHeight);
			};

			window.addEventListener('resize', updateWindowDimensions);

			return () => window.removeEventListener('resize', updateWindowDimensions);
		}
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		return () => {};
	}, []);

	return screenHeight;
}
