import Image from 'next/legacy/image';
import { useRouter } from 'next/router';
import { FunctionComponent, useCallback, useState } from 'react';
import styled from 'styled-components';

import logoBlack from '../../assets/logo-black.svg';
import Theme from '../../styles/Theme';

import { MapMenu } from './MapMenu';
import { IconMenuBurgerSVG } from './svg/IconMenuBurgerSVG';

interface Props {
	title: string;
	translation: boolean;
}

const StyledHeader = styled.header`
	height: ${(props) => 56 / props.theme.fontSizeBase}rem;
	display: flex;
	align-items: center;
	padding: 0 ${(props) => 20 / props.theme.fontSizeBase}rem;

	background: ${(props) => props.theme.white};
	border-bottom: 1px solid ${(props) => props.theme.borderColor};

	#logo {
		cursor: pointer;
	}

	svg:last-child {
		cursor: pointer;
	}
`;

const StyledTitle = styled.h1`
	flex: 1;
	text-align: center;
	margin: 0;

	font-family: 'Plus Jakarta Sans', sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: ${(props) => 16 / props.theme.fontSizeBase}rem;
	line-height: ${(props) => 20 / props.theme.fontSizeBase}rem;

	letter-spacing: -1px;

	color: ${(props) => props.theme.black};
`;

export const MapHeader: FunctionComponent<Props> = ({ title, translation }: Props) => {
	const router = useRouter();
	const [menuOpen, setMenuOpen] = useState(false);

	const toggleMenu = useCallback(() => {
		setMenuOpen((prev) => !prev);
	}, []);

	const goToHome = useCallback(() => {
		router.push('/');
	}, [router]);

	return (
		<StyledHeader>
			{ menuOpen && <MapMenu onClose={toggleMenu} translation={translation} /> }
			<Image id="logo" src={logoBlack} alt="logo" width={60} height={30} onClick={goToHome}/>
			<StyledTitle>{ title }</StyledTitle>
			<IconMenuBurgerSVG color={Theme.black} width={24} height={24} onClick={toggleMenu} />
		</StyledHeader>
	);
};
